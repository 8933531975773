<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

import index from "@/api";

export default {
  name: 'App',
  watch:{
    // 控制滚动条位置
    '$route':function(to,from){
      console.log(to,from)
      if(to.meta.keepAlive){
        document.body.scrollTop = to.meta.scrollTop
        document.documentElement.scrollTop = to.meta.scrollTop
      }
      this.SEO()
    }
  },
  methods:{
    SEO(){
      index.Seo()
          .then(res=>{
            console.log(res.data.keyword,'==========')
            document.title = this.$route.meta.title + ' | ' + res.data.title
            document.querySelector('meta[name="keywords"]').content = res.data.keyword
            document.querySelector('meta[name="description"]').content = res.data.description
          })
    }
  }
}
</script>

<style lang="less" scopeds>

@import url('./assets/css/clear.css');
@import url('./assets/font/font/iconfont.css');
#app {
  font-family: Noto Sans SC;
  font-style: normal;
  //font-weight: 400;
}
</style>
