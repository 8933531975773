import request  from "@/uitils/request";
//首页banner图
export default {
    BannerApi(){
        return request({
            url: '/official/banner/banners',
            method: 'POST',
        })
    },
    ProductListApi(data){
        return request({
            url: '/official/ProductCenter/ProductCenters',
            method: 'POST',
            data
        })
    },
    aboutUs(data){
        return request({
            url:'/official/about/queryDkAboutList',
            method:'POST',
            data
        })
    },
    News(data){
        return request({
            url:'/official/news/queryDkNewsList',
            method:'POST',
            data
        })
    },
    //新闻详情
    NewsInfo(data){
        return request({
            url:'/official/news/getDkNews?id='+data,
        })
    },
    //隐私政策
    privacyPolicy(){
        return request({
            url:'/official/privacyPolicy/queryDkPrivacyPolicy',
            method:'POST'
        })
    },
    //服务政策
    policy(){
        return request({
            url:'/official/policy/getDkPolicy',
            method:'POST'
        })
    },

    //服务政策
    useAgreement(){
        return request({
            url:'/official/useAgreement/queryDkUseAgreement',
            method:'POST'
        })
    },
    //关注点可
    followDianke(){
        return request({
            url:'/official/followDianke/queryDkFollowDiankes',
            method:'POST'
        })
    },
    //解决方案
    DkSolution(){
        return request({
            url:'/official/solution/getDkSolution',
            method:'POST'
        })
    },
    //logo
    DKlogo(){
        return request({
            url:'/official/logo/queryDkLogos',
            method:'POST'

        })
    },
    Seo(){
        return request({
            url:'/official/siteManagement/query',
            method:'POST'
        })
    }

}

