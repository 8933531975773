import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            name: 'dianke',
            component: () => import( /* webpackChunkName: "about" */ '@/views/dianke'),
            // redirect: 'Index',
            children: [{
                path: '/',
                name: 'Index',
                component: () => import( /* webpackChunkName: "about" */ '@/views/index'),
                meta:{
                    title:'首页',

                }
            }, {
                path: '/Brand',
                name: 'Brand',
                component: () => import( /* webpackChunkName: "about" */ '@/views/Brand'),
                meta:{
                    title:'点可-品牌'
                }
            }, {
                path: '/Product',
                name: 'Product',
                component: () => import( /* webpackChunkName: "about" */ '@/views/Product'),
                meta:{
                    title:'点可-产品'
                }
            }, {
                path: '/ProductInfo',
                name: 'ProductInfo',
                component: () => import( /* webpackChunkName: "about" */ '@/views/ProductInfo'),
                meta:{
                    title:'点可-产品'
                }
            },{
                path:'/ContactUs',
                name:'ContactUs',
                component: () => import( /* webpackChunkName: "about" */ '@/views/ContactUs'),
                meta:{
                    title:'点可-联系我们'
                }
            },{
                path:'/News',
                name:'News',
                component: () => import( /* webpackChunkName: "about" */ '@/views/News'),
                meta:{
                    title:'点可-新闻'
                }
            },{
                path:'/NewsInfo',
                name:'NewsInfo',
                component: () => import( /* webpackChunkName: "about" */ '@/views/NewsInfo'),
                meta:{
                    title:'点可-新闻'
                }
            },{
                path:'/Service',
                name:'Service',
                redirect:'/Service/policy',
                component: () => import( /* webpackChunkName: "about" */ '@/views/Service'),
                meta:{
                    title:'点可-服务'
                },
                children:[{
                    path:'/Service/policy',
                    name:'policy',
                    component: () => import( /* webpackChunkName: "about" */ '@/views/policy'),
                    meta: {
                        title:'点可-服务',
                        keepAlive: true,
                        scrollTop: 400,
                    },
                },
                    {
                        path:'/Service/problem',
                        name:'problem',
                        component: () => import( /* webpackChunkName: "about" */ '@/views/problem'),
                        meta: {
                            title:'点可-服务',
                            keepAlive: true,
                            scrollTop: 400,
                        },
                    }]
            },{
                path:'/Solution',
                name:'Solution',
                component: () => import( /* webpackChunkName: "about" */ '@/views/Solution'),
                meta:{
                    title:'点可-解决方案'
                },
            },{
                path:'/Join',
                name:'Join',
                component: () => import( /* webpackChunkName: "about" */ '@/views/Join'),
                meta:{
                    title:'点可-加入我们'
                },
            },{
                path:'/Privacy',
                name:'Privacy',
                component: () => import( /* webpackChunkName: "about" */ '@/views/Privacy'),
                meta:{
                    title:'点可-隐私政策'
                },
            },{
                path:'/Agreement',
                name:'Agreement',
                component: () => import( /* webpackChunkName: "about" */ '@/views/agreement'),
                meta:{
                    title:'点可-使用协议'
                },
            },]
        },

    ],

    scrollBehavior(to, from, savedPosition) {
        return {
            x: 0,
            y: 0
        }
    },
    mode: 'history',
    base: "/",

})

router.beforeEach((to, from, next) => {
    // 百度统计
    // 创建vuerouter后，调用beforeEach方法，每个路由跳转时都将其跳转的路由推给百度
    if (_hmt) {
        if (to.path) {
            _hmt.push(['_trackPageview', '/' + to.fullPath]);
        }
    }
    next();
});

export default router
