import axios from 'axios'
// import { MessageBox, Message } from 'element-ui'
// import { Toast } from 'vant'
// import store from '@/store'
// import { getToken } from '@/utils/auth'

// create an axios instance
const service = axios.create({
    // baseURL: 'http://192.168.1.49/cons', // url = base url + api url
    baseURL: 'https://dianke-aiot.com/official', // url = base url + api url
    timeout: 10000, // api timeout
    headers:{
        'Content-Type' : 'multipart/form-data'
    }
})


// api interceptor
service.interceptors.request.use(
    config => {
        // do something before api is sent
        // if (store.getters.token) {
        // let each api carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation
        // config.headers['X-Token'] = getToken()
        // config.headers['Authorization'] = 'Bearer ' + getToken()
        // }
        return config
    },
    error => {
        // do something with api error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */
    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 0) {
            // Message({
            //   message: res.message || 'Error',
            //   type: 'error',
            //   duration: 5 * 1000
            // })
            // Toast.fail(res.message || 'Error')
            // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
            if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
                // to re-login
                // MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
                //   confirmButtonText: 'Re-Login',
                //   cancelButtonText: 'Cancel',
                //   type: 'warning'
                // }).then(() => {
                //   store.dispatch('user/resetToken').then(() => {
                //     location.reload()
                //   })
                // })
            }
            return Promise.reject(new Error(res.message || 'Error'))
        } else {
            return res
        }
    },
    error => {
        console.log('err' + error) // for debug
        // Message({
        //   message: error.message,
        //   type: 'error',
        //   duration: 5 * 1000
        // })
        // Toast.fail(error.message || 'Error')
        return Promise.reject(error)
    }
)

export default service
